:root {
  --background-color: #1a202c;
  --foreground-color: #edf2f7;
  --secondary-color: #bdc1c5;
}

/* Display-P3 color, when supported. */
@supports (color: color(display-p3 1 1 1)) {
  :root {
    --background-color: color(display-p3 0.1065 0.1248 0.1686);
    --foreground-color: color(display-p3 0.933 0.9484 0.9665);
    --secondary-color: color(display-p3 0.744 0.7563 0.7708);
  }
}

* {
  box-sizing: border-box;
}

body {
  background: var(--background-color);
  padding: 0;
  margin: 0;
  overflow: hidden;

  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 10px;
  color: var(--foreground-color);
  line-height: 1.4;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  font-feature-settings: "kern", "liga";
}

.clock-tick circle,
circle.clock-track-hide-overflow {
  fill: var(--background-color);
}

.clock-tick text {
  font-family: "Futura", "Helvetica Neue", helvetica, arial, sans-serif;
  letter-spacing: -0.03em;
  /* The font-size is set programatically, relative to the size of the clock arms. */
  fill: var(--foreground-color);
  text-anchor: middle;
}

a,
a:hover,
a:visited {
  color: inherit;
}

footer {
  position: fixed;
  bottom: 0;
  left: 0;
  padding: 2em;
  font-size: 1.1em;
  color: var(--secondary-color);
}

@media (max-width: 39em) {
  body {
    font-size: 8px;
  }

  footer {
    padding: 3em 2em;
  }
}
